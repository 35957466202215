import projectOne from "../assets/kshree.png";
import projectTwo from "../assets/project-2.png";
import projectThree from "../assets/project-3.png";

const projects = {
  1: {
    title: "Kshree App",
    image: projectOne,
    description: (
      <>
        <p>
        Developed the K-Shree App, a dynamic React Native
        Android application, to empower Kudumbashree
        Self-Help Groups (SHGs)
        </p>
      </>
    ),
    github: "https://github.com/karthikvijay5227/Kshree",
    demo: "https://github.com/karthikvijay5227/Kshree/releases/tag/v1.0.0",
  },
  /*2: {
    title: "Productivity App",
    image: projectTwo,
    description: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </>
    ),
    github: "https://github.com",
    demo: "https://netlify.com",
  },
  3: {
    title: "Food Delivery App",
    image: projectThree,
    description: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </>
    ),
    github: "https://github.com",
    demo: "https://netlify.com",
  },*/
};

export default projects;
