import React, { useState } from 'react';
import backgroundMusic from './theme.mp3';
import './styles/BackgroundMusic.css'

function BackgroundMusic() {

    const [musicPlaying, setMusicPlaying] = useState(false);

    function toggleMusic() {
        const audio = document.getElementById('background-audio');

        if (!musicPlaying) {
            audio.play();
        } else {
            audio.pause();
        }

        setMusicPlaying(!musicPlaying);
    }
  return (
    <div className="audio-player">
        <button onClick={toggleMusic}>
            {musicPlaying ? 'Pause Music' : 'Play Music'}
        </button>
        <audio id="background-audio" controls autoPlay loop>
            <source src={backgroundMusic} type="audio/mp3" />
        </audio>
    </div>
  );
}

export default BackgroundMusic;


