import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./nav/Nav.js";
import About from "./about/About";
import Skills from "./skills/Skills";
import Projects from "./projects/Projects";
import Contact from "./contact/Contact";
import "./styles/app.css";
import Background from "./background/Background.js";
import PlayerStats from "./playerStats/PlayerStats.js";
import BackgroundMusic from './BackgroundMusic';
/* import WriterMode from "./CreativeCentre/CreaiveCentre.js";
import CreativeCentre from "./CreativeCentre/CreativeCentre.js"; */

const App = () => {
  return (
    <Router>
      <div>
        <Nav />
        <Background />
        <Routes>
          <Route path="/" element={<About />} />
          {/* <Route path="/CreativeCentre" element={<CreativeCentre />} /> */}
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <PlayerStats />
      </div>
      <div className="App">
        <BackgroundMusic />
      </div>
    </Router>
  );
  
};


export default App;
