const subheadingsData = {
  1: [
    {
      title: "About Me",
      content: (
        <>
          <p>
          I am a person who loves to explore the world, 
innovate sound ideas for it,
Develop the innovative idea for practical use,
and inspire others to do the same... Because that's what we are here for!!!
          </p>
        </>
      ),
    },
  ],
  2: [
    {
      title: "BTech in Computer Science and Engineering",
      content: (
        <>
          <p>
            College Of Engineering Chengannur, 2020-24
          </p>
        </>
      ),
    },
    
  ],
  3: [
    {
      title: "GOOGLE DSC LEAD",
      content: (
        <>
          <p>
          Officially Starting my position of GDSC Lead of College Of Engineering Chengannur, Year 2023-24
          </p>
        </>
      ),
    },
    {
      title: "CYBERSECURITY FACILITATOR",
      content: (
        <>
          <p>
          Being Cybersecurity Interest Group Lead, I have been curating courses and tasks for Cybersecurity in GTech Mulearn along with my team with the help of Expert Mentors, 
          along with conducting office hours with the students once every week and having a healthy discussion about the Cybersecurity topic with them and making their interest work
          </p>
        </>
      ),
    },
    {
      title: "IEEEXtreme Campus Ambassador",
      content: (
        <>
          <p>
          Being the Campus ambassador for IEEEXtreme 16.0, a world-level coding competition hosted by IEEE, 
          I have made sure every student shall get acknowledged with IEEEXtreme and provided them assistance in participating in the competition.
          </p>
        </>
      ),
    },
  ],
4: [
  {
    title: "Certified Penetration Tester",
    content: (
      <> Red Team Hacker Academy
      Credential ID: RTHACPT975 </>
    ),
  },
],

5: [
  {
    title: "IEEE",
    content: (
      <> IEEE is the world's largest technical professional organization dedicated to advancing technology for the benefit of humanity. </>
    ),
    
  },
  {
    title: "Google Developer Student Clubs",
    content: (
      <> Google Developer Student Clubs are university based community groups for students interested in Google developer technologies. </>
    ),
  },
  {
    title: "GTech Mulearn",
    content: (
      <> GTech Mulearn is a platform for students to learn and explore new technologies. </>
    ),
  },
  {
    title: "Codeasia.org",
    content: (
      <></>
    ),
  },


],

};

export default subheadingsData;
